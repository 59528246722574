.button{
    color: #fff;
    background-color: #F85C70;
    border-color: #F85C70;
    transition: background-color 0.3s, color 0.3s;
    padding: 4px 15px;
} 

@media screen and (min-width: 319px) {
    .header .header-content .header-title  {
      line-height: 1.5; /* Adjust this value as needed */
    }
  }
  