.brand-img {
    transition: transform 0.5s ease; /* Apply transition effect to transform property */
  }
  
.show {
transform: translateY(0); /* Move image to its original position */
}

.hide {
transform: translateY(-100%); /* Move image upwards to hide it */
}



  